.flex-containerV {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: left;
  margin: 0px 10px 10px 10px;
}
.flex-container2V {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: left;
  margin: 0px 10px 10px 10px;
}
.flex-containerH {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 10px 5px;
}
.flex-item1 {
  margin: 5px;
}
.flex-item2 {
  margin: 10px;
}
.flex-item3 {
  margin: 30px 10px 10px 10px;
}
.flex-noteItem {
  margin: 10px;
}
