.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 10px 5px;
}
.flex-containerV {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0px 0px 10px 5px;
}
.flex-container-right {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 0px 10px 10px;
}
.flex-item1 {
  margin: 5px;
}
.flex-item2 {
  margin: 5px 5px 5px 30px;
}
.flex-item3 {
  margin: 10px;
}
