.flex-containerH {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 10px 5px;
}
.flex-containerH2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 0px 10px 10px;
}
.flex-containerH3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px 10px 5px;
}
.flex-container-right {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 0px 10px 10px;
}
.flex-containerTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 0px 0px;
}
.flex-containerTitle2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 0px 0px 0px;
}
.flex-containerOneItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 10px 10px;
}
.text-large {
  font-size: large;
}
.text-medium {
  font-size: medium;
}
.text-small {
  font-size: small;
}
.flex-item1 {
  margin: 5px;
}
.flex-item2 {
  margin: 5px 5px 5px 30px;
}
.flex-item3 {
  margin: 10px;
}
