.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}

.flex-containerV {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px 20px 5px;
}
.flex-item1 {
  margin: 5px;
}
.flex-item2 {
  margin: 10px;
}
.flex-item3 {
  margin: 30px 10px 10px 10px;
}
