.part-image {
  float: left;
  //height: 200px;
  //width: 200px;
  margin-right: 20px;
  margin-left: 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    //height: 120px;
    width: 200px;
    display: block;
    margin: 0 auto;
  }
}
.flex-containerV {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: left;
  margin: 0px 10px 10px 10px;
}
.flex-container2V {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: left;
  margin: 0px 10px 10px 10px;
}
.flex-containerH {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 10px 5px;
}
.flex-item1 {
  margin: 5px;
}
.flex-item2 {
  margin: 10px;
}
.flex-item3 {
  margin: 30px 10px 10px 10px;
}
